import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

const RelatedPosts = props => {
  const randomSelect = (array, num) => {
    let newArray = []

    while (newArray.length < num && array.length > 0) {
      const rand = Math.floor(Math.random() * array.length)
      newArray.push(array[rand])
      array.splice(rand, 1)
    }

    return newArray
  }

  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost {
        nodes {
          title
          slug
          id
          category {
            id
          }
        }
      }
    }
  `)

  const baseposts = data.allContentfulBlogPost.nodes.filter(
    node => node.id !== props.id && node.category.map(cat => (cat.id))[0] === props.catId
  )
  const relatedposts = randomSelect(baseposts, props.a_number)

  // 関連記事がなければ表示しない
  if (!relatedposts.length) {
    return null;
  }

  return (
    <div className="relatedposts">
      <h3>関連記事</h3>
      <ul>
        {relatedposts.map(node => {
          return (
            <li key={node.slug}>
              <Link to={`/blog/post/${node.slug}/`}>{node.title}</Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default RelatedPosts
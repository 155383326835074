import React from "react"
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faListUl } from "@fortawesome/free-solid-svg-icons"

// スムーススクロール
import { Link } from "react-scroll"

// ハッシュ化用モジュールの読み込み
const crypto = require("crypto")

const TableOfContents = props => {

  // Contentfulの見出しを定義
  const headingTypes = [BLOCKS.HEADING_2, BLOCKS.HEADING_3]

  // 見出しを配列で格納
  const headings = props.json.content.filter(item => headingTypes.includes(item.nodeType))

  // 見出しのnodeTypeをdocumentとして利用
  const document = {
    nodeType: "document",
    content: headings,
  }

  const options = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => {
        // H2タグのテキストをハッシュ化してidに利用
        const anchor = crypto.createHash("md5").update(node.content[0].value).digest("hex")
        return (
          <li>
            <Link
              to={anchor}
              activeClass="active"
              smooth={true}
              duration={500}
            >
              {children}
            </Link>
          </li>
        )
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        // H3タグのテキストをハッシュ化してidに利用
        const anchor = crypto.createHash("md5").update(node.content[0].value).digest("hex")
        return (
          <li className="toc__child">
            <Link
              to={anchor}
              activeClass="active"
              smooth={true}
              duration={500}
            >
              {children}
            </Link>
          </li>
        )
      },
    }
  }

  return (
    <nav className="toc">
      <p className="toc__title"><FontAwesomeIcon icon={faListUl} />目次</p>
      <ul>
        {documentToReactComponents(document, options)}
      </ul>
    </nav>
  )
}

export default TableOfContents
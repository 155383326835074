import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import RelatedPosts from "../components/relatedposts"
import TableOfContents from "../components/tableofcontents"
import Share from "../components/share"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock, faSyncAlt, faFolderOpen, faChevronLeft, faChevronRight, faCheckSquare } from "@fortawesome/free-solid-svg-icons"

import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"

// gatsby-source-contentful のv4系以降に利用
import { renderRichText } from "gatsby-source-contentful/rich-text"

// コードブロックのシンタックスハイライト
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { okaidia } from "react-syntax-highlighter/dist/cjs/styles/prism";

import Seo from "../components/seo"

// ハッシュ化用モジュールの読み込み
const crypto = require("crypto")

const options = {
  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => {
      // H2タグのテキストをハッシュ化してidに利用
      const anchor = crypto.createHash("md5").update(node.content[0].value).digest("hex")
      return (
        <h2 id={anchor}>
          <FontAwesomeIcon icon={faCheckSquare} />
          {children}
        </h2>
      )
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      // H3タグのテキストをハッシュ化してidに利用
      const anchor = crypto.createHash("md5").update(node.content[0].value).digest("hex")
      return (
        <h3 id={anchor}>
          {children}
        </h3>
      )
    },
    [BLOCKS.EMBEDDED_ASSET]: node => (
      <GatsbyImage
        image={node.data.target.gatsbyImageData}
        alt={
          node.data.target.description
            ? node.data.target.description
            : node.data.target.title
        }
      />
    ),
    [INLINES.HYPERLINK]: (node, children) => {
      if (
        // ルート相対用
        node.data.uri.startsWith('/') ||
        // 本番用
        node.data.uri.startsWith('https://frontsensei.com')
      ) {
        // 内部リンク
        return <Link to={node.data.uri}>{children}</Link>;
      }
      // 外部リンク
      return <a href={node.data.uri} target="_blank" rel="noopener noreferrer">{children}</a>;
    },
    // コードブロックをdivで括る
    [BLOCKS.PARAGRAPH]: (node, children) => {
      if (
        node.content.length === 1 &&
        node.content[0].marks.find((x) => x.type === "code")
      ) {
        return <div>{children}</div>;
      }
      return <p>{children}</p>;
    },
  },
  // コードブロック
  renderMark: {
    [MARKS.CODE]: code,
  },
  renderText: text => {
    return text.split("\n").reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment]
    }, [])
  },
}

// コードブロックのシンタックスハイライト
function code(text) {
  text.shift(); // コードブロックのfalseを削除
  const language = text.shift(); // コードブロックの1行目の言語指定をClassに利用後削除
  text.shift(); // コードブロックの1行目の改行を削除

  const value = text.reduce((acc, cur) => {
    if (typeof cur !== "string" && cur.type === "br") {
      return acc + "\n";
    }
    return acc + cur;
  }, "");

  return (
    <SyntaxHighlighter language={language} style={okaidia}>
      {value}
    </SyntaxHighlighter>
  );
}

const blogpostTemplate = ({ data, pageContext, location }) => (
  <Layout>
    <Seo
      pagetitle={data.contentfulBlogPost.title}
      pagedesc={`${documentToPlainTextString(
        JSON.parse(data.contentfulBlogPost.content.raw)
      ).slice(0, 70)}…`}
      pagepath={location.pathname}
      blogimg={`https:${data.contentfulBlogPost.eyecatch.file.url}`}
      pageimgw={data.contentfulBlogPost.eyecatch.file.details.image.width}
      pageimgh={data.contentfulBlogPost.eyecatch.file.details.image.height}
    />
    <div className="eyecatch">
      <figure>
        <GatsbyImage
          image={data.contentfulBlogPost.eyecatch.gatsbyImageData}
          alt={data.contentfulBlogPost.eyecatch.description}
        />
      </figure>
    </div>
    <article className="content">
      <div className="container">
        <h1 className="bar">{data.contentfulBlogPost.title}</h1>
        <aside className="info">
          {data.contentfulBlogPost.publishDate > data.contentfulBlogPost.updatedAt
            ? (
              <div className="time">
                <time dateTime={data.contentfulBlogPost.publishDate}>
                  <FontAwesomeIcon icon={faClock} />
                  {data.contentfulBlogPost.publishDateJP}
                </time>
                <span>
                  <FontAwesomeIcon icon={faSyncAlt} />
                  {data.contentfulBlogPost.updatedAtJP}
                </span>
              </div>
            )
            : (
              <div className="time">
                <span>
                  <FontAwesomeIcon icon={faClock} />
                  {data.contentfulBlogPost.publishDateJP}
                </span>
                <time dateTime={data.contentfulBlogPost.updatedAt}>
                  <FontAwesomeIcon icon={faSyncAlt} />
                  {data.contentfulBlogPost.updatedAtJP}
                </time>
              </div>
            )
          }
          <div className="cat">
            <FontAwesomeIcon icon={faFolderOpen} />
            <ul>
              {data.contentfulBlogPost.category.map(cat => (
                <li className={cat.categorySlug} key={cat.id}>
                  <Link to={`/blog/cat/${cat.categorySlug}/`}>{cat.category}</Link>
                </li>
              ))}
            </ul>
          </div>
          <TableOfContents json={JSON.parse(data.contentfulBlogPost.content.raw)} />
        </aside>
        <div className="postbody">
          {renderRichText(
            data.contentfulBlogPost.content,
            options
          )}
        </div>
        <Share
          title={data.contentfulBlogPost.title}
          url={`${data.site.siteMetadata.siteUrl}${location.pathname}`}
          description={`${documentToPlainTextString(
            JSON.parse(data.contentfulBlogPost.content.raw)
          ).slice(0, 70)}…`}
        />
        <ul className="postlink">
          {pageContext.next && (
            <li className="prev">
              <Link to={`/blog/post/${pageContext.next.slug}/`} rel="prev">
                <FontAwesomeIcon icon={faChevronLeft} />
                <span>{pageContext.next.title}</span>
              </Link>
            </li>
          )}
          {pageContext.previous && (
            <li className="next">
              <Link to={`/blog/post/${pageContext.previous.slug}/`} rel="next">
                <span>{pageContext.previous.title}</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </li>
          )}
        </ul>
        {/* 関連記事は複数カテゴリーを持つ場合でも最初のカテゴリーにのみ対応 */}
        <RelatedPosts a_number={5} id={pageContext.id} catId={data.contentfulBlogPost.category.map(cat => (cat.id))[0]} />
      </div>
    </article>
  </Layout>
)

export const query = graphql`
  query($id: String!) {
    contentfulBlogPost(id: { eq: $id }) {
      title
      publishDateJP:publishDate(formatString: "YYYY年MM月DD日")
      publishDate
      updatedAtJP:updatedAt(formatString: "YYYY年MM月DD日")
      updatedAt
      category {
        category
        categorySlug
        id
      }
      eyecatch {
        gatsbyImageData(layout: FULL_WIDTH)
        description
        file {
          details {
            image {
              width
              height
            }
          }
          url
        }
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(layout: FULL_WIDTH)
            title
            description
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

export default blogpostTemplate